<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-black">
            <template slot="header">
              <div class="image-container">
                <img src="img/tolsporA5.png" alt=""/>
              </div>
              <h1 class="card-title"></h1>
            </template>

            <div>
              <ValidationProvider
                name="password"
                rules="required|confirmed:confirmation|password"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="password"
                  type="password"
                  placeholder="New Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="password && errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed && password }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="confirm"
                vid="confirmation"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="confirmation"
                  type="password"
                  placeholder="Confirm New Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="password && errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed && password }]">
                </base-input>
              </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="tolsporagulur" class="mb-3" size="lg" block>
                Reset Password
              </base-button>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import api from "../../services/api";

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("password", {
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
    return strongRegex.test(value);
  },
  message: "Password must contain at least one uppercase letter, one lowercase letter, and one special character"
});

export default {
data() {
  return {
    email: "",
    password: "",
    confirmation: "",
    userId: "",
    resetCode: ""
  };
},
async created() {
  //used to distinguish between the forgotten pass and the first time reset
  const forgotten = this.$route.query.forgotten;
  console.log("forgotten", forgotten);
  if(forgotten === "true")
  {
    this.userId = this.$route.query.userId;
    console.log(this.userId);
    this.resetCode = this.$route.query.resetCode;
    console.log(this.resetCode);
  } else {
    this.userId = this.$route.params.userId;
    console.log(this.userId);
    this.resetCode = this.$route.params.resetCode;
    console.log(this.resetCode);
  }
},
methods: {
  async submit() {
    try {
      await api.post(`/identity/ResetPassword`, {
        userId: this.userId, // use this line
        token: this.resetCode, // use this line
        password: this.password
      });
      console.log("Password has been reset!");
      this.$notify({
        message: `Reset password success`,
        icon: "tim-icons icon-check-2",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
        timeout: 2000,
      });
      this.$router.push({ name: 'Login' });
    } catch (error) {
      console.error("An error occurred while resetting the password.");
      this.$notify({
        message: `Reset failed: ${error.message}`,
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
    }
  }
}
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.image-container {
  height: 250px; /* Adjust as needed */
  overflow: hidden;
  position: relative;
}
</style>  