<template>
  <div class="row-md-12">
    <div class="row mt-5">
      <div class="col-md-12">
        <create-project-form v-if="showCreateProjectForm" @close="closeCreateProjectForm" @refreshData="fetchData"></create-project-form>
      </div>
      <div class="col-12">
        <card :title="$t('projects.projects')" :onCustomMethod="customMethod" :customMethodIcon="customIcon">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input>
                <el-input
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @keyup.enter.native="search"
                  @clear="handleClearSearch"
                >
                </el-input>
              </base-input>

            </div>
            <el-base-table
              :tableData="projects"
              :tableColumns="tableColumns"
              :allowClick="true"
              @row-click="goToProject"
            />
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
            @input="updatePage"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div></div
></template>
<script>
import { BasePagination } from "@/components";
import CreateProjectForm from "./CreateProjectForm";
import api from "../../services/api"
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Table, TableColumn, Select, Option } from 'element-ui';
import ElBaseTable from '../../components/ElBaseTable.vue';

export default {
  components: {
    BasePagination,
    CreateProjectForm,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-base-table': ElBaseTable
  },
  data() {
    return {
      loading: false,
      enableProjectCreation: false,
      showCreateProjectForm: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [],
      searchQuery: '',
      projects: [],
      isMobile: window.innerWidth <= 768,
      initalPage: true
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    customIcon() {
    return this.enableProjectCreation ? 'tim-icons icon-simple-add' : 'tim-icons icon-refresh-02';
    },
    customMethod() {
      return this.enableProjectCreation ? this.openCreateProjectForm : this.sync;
    }
  },
  methods: {
    search(){
      this.pagination.currentPage = 1;
      this.pagination.total = 0;

      let getProjectsUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
      if(this.userRole === 'User'){
        projectUrl = projectUrl + '&projectStatusId=2'
      }

      api.get(getProjectsUrl)
      .then(response => {
          this.pagination.total = response.data.totalItems;
          this.projects = response.data.result.map(item => {
          return {
              id: item.id,
              key: item.key,
              name: item.name,
              description: item.description,
              status: this.GetStatusTranslation(item.projectStatusId)

          };
          });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    updatePage(item){
      this.pagination.currentPage = item;
      this.fetchData()
    },
    openCreateProjectForm() {
      this.showCreateProjectForm = true;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    closeCreateProjectForm()
    {
        this.showCreateProjectForm = false;
    },
    handleClearSearch()
    {
      this.fetchData();
    },
    goToProject(item) {
      this.$router.push({ name: 'Project', params: { projectId: item.id } });
    },
    fetchData() {
      let projectUrl = ''
      if(this.searchQuery === '')
      {
        projectUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
      }
      else
      {
        projectUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
      }

      if(this.userRole === 'User'){
        this.tableColumns = [
        { prop: 'key', label: this.$t('projects.key'), minWidth: 150 },
        { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
        { prop: 'description', label: this.$t('common.description'), minWidth: 150 },
        { label: this.$t('common.info'), minWidth: 250, showOnMobile: true, isCompound: true, compound: { 'projects.key': 'key', 'common.name': 'name', 'common.description': 'description'}}
        ]
        projectUrl = projectUrl + '&projectStatusId=2'
      }
      else{
        this.tableColumns = [
        { prop: 'key', label: this.$t('projects.key'), minWidth: 150 },
        { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
        { prop: 'description', label: this.$t('common.description'), minWidth: 150 },
        { prop: 'status', label: this.$t('common.status'), minWidth: 60 },
        { label: this.$t('common.info'), minWidth: 250, showOnMobile: true, isCompound: true, compound: { 'projects.key': 'key', 'common.name': 'name', 'common.description': 'description','common.status': 'status'}}
        ]
      }

      api.get(projectUrl)
      .then(response => {
          this.pagination.total = response.data.totalItems;
          this.projects = response.data.result.map(item => {
          return {
              id: item.id,
              key: item.key,
              name: item.name,
              description: item.description,
              status: this.GetStatusTranslation(item.projectStatusId)
          };
          });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    sync() {
      this.loading = true;
      let syncUrl = `/Project/Sync`
      api.post(syncUrl)
      .then(response => {
        console.log(response)
        if(response.data.success){
          this.$notify({
            message: `${response.data.projectsCreated} ${this.$t('projects.projectsCreated')} <br> ${response.data.projectsUpdated} ${this.$t('projects.projectsUpdated')}`,
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
          this.fetchData();
        }
        else{
          this.$notify({
          message: this.$t('common.somethingWentWrong') + " - " + response.data.errorMessage,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
        }
        this.loading = false;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.loading = false;
        this.$notify({
          message: this.$t('common.somethingWentWrong'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    },
    GetStatusTranslation(statusId){
      switch(statusId){
        case 1:
          return this.$t('projectStatus.notStarted')
        case 2:
          return this.$t('projectStatus.active')
        case 3:
          return this.$t('projectStatus.onHold')
        case 4:
          return this.$t('projectStatus.finished')
      }
    }
  },
  created() {
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role
    if (!Cookies.get('enableProjectCreation')) {
      let url = "Customers/CustomerSettings?key=EnableProjectCreation";
      api.get(url)
        .then(response => {
          if (response.data.length > 0) {
            Cookies.set('enableProjectCreation', response.data[0].value === 'true');
            this.enableProjectCreation = response.data[0].value === 'true';
          }
          else {
            Cookies.set('enableProjectCreation', false);
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    } else {
    // If the cookie exists, set the component data property
        this.enableProjectCreation = Cookies.get('enableProjectCreation') === 'true';
    }
    this.fetchData();
  },
};
</script>
<style></style>
