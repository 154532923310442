<!-- QrScanner.vue -->
<template>
  <div>
    <div class="col-sm d-flex align-left">
        <button type="button" class="btn btn-info" @click="showOptionsModal = true">
        <i class="fas fa-camera fa-2x" ></i>
        </button>
    </div>
    <modal ref="modal" :show="showModal" @update:show="showModal = $event">
      <qrcode-stream v-if="scannerActive" @decode="onDecode"></qrcode-stream>
    </modal>

    <modal :show.sync="showOptionsModal">
      <h3 class="card-title" >{{$t('tools.ReserveOrUnreserve')}}</h3>
      <div class="row">
        <div class="col-sm">
          <base-button block class="reserve-button" @click="this.activateScannerReserve">{{$t('tools.reserve')}}</base-button>
        </div>
        <div class="col-sm">
          <base-button block type="danger" @click="this.activateScannerReturn">{{$t('tools.unreserve')}}</base-button>
        </div>
    </div>
  </modal>


    <!-- <div v-if="decodedContent">Decoded content: {{ decodedContent }}</div> -->
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader"
import { Modal } from "@/components"

export default {
  components: {
    QrcodeStream,
    Modal,
  },
  data () {
    return {
      scannerActive: false,  // This will control whether the scanner is active
      showModal: false,
      showOptionsModal: false,
      decodedContent: '',
      reserve: false,
      return: false
    }
  },
  methods: {
    activateScannerReserve() {
      this.showOptionsModal = false;
      this.reserve = true;
      this.scannerActive = true;  // toggle the scanner when the button is clicked
      this.showModal = true;  // Open the modal
    },
    activateScannerReturn() {
      this.showOptionsModal = false;
      this.return = true;
      this.scannerActive = true;  // toggle the scanner when the button is clicked
      this.showModal = true;  // Open the modal
    },
    onDecode (content) {
      this.decodedContent = content;
      if(this.reserve === true){
        console.log('here')
        this.$emit('decodedReserve', content);  // Emit an event with the decoded content
      }
      if(this.return === true){
        this.$emit('decodedReturn', content);  // Emit an event with the decoded content
      }

      this.reserve = false;
      this.return = false;
      this.scannerActive = false;  // toggle the scanner when the decode has happened
      this.showModal = false;
      this.$refs.modal.closeModal();  // Close the modal
    },
  }
}
</script>

<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.big-icon {
  font-size: 25px;
}
.reserve-button{
  background: $vue;
}
</style>
