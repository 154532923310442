<!-- eslint-disable vue/no-mutating-props -->
<template>
<card :title="$t('tools.loanRent')" :onCancel="cancel">
  <div class="row">
  </div>
    <div class="row">
      <div class="col-md-6">
        <label class="col-md-6 col-form-label">{{$t('tools.loanRentTo')}} *</label>
        <base-input :placeholder="$t('common.name')" ref="externalUserInput" v-model="externalUser"> </base-input>
      </div>
      <div class="col-md-6">
        <label class="col-md-7 col-form-label">{{$t('tools.loanOrRent')}} *</label>
        <div class="row">
          <el-select
              ref="loanRentInput"
              class="col-md-12"
              :class="selectClass"
              size="large"
              :placeholder="$t('tools.loanRent')"
              allow-create
              v-model="loanOrRent"
            >
              <el-option :value="$t('tools.loan')">{{$t('tools.loan')}}</el-option>
              <el-option :value="$t('tools.rent')">{{$t('tools.rent')}}</el-option>
            </el-select>
        </div>

      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
           <base-checkbox class="mb-3" v-model="connectToProject" @input="onConnectToProjectChange">{{$t('projects.connectToProject')}}</base-checkbox>
      </div>
    </div>
    <hr v-if="connectToProject">
    <div class="row" v-if="connectToProject">
      <card>
        <h4 class="card-title">{{$t('projects.projects')}}</h4>

        <div class="col-md-12">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <base-input>
                  <el-input
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    :placeholder="$t('common.searchRecords')"
                    v-model="searchQuery"
                    aria-controls="datatables"
                    @keyup.enter.native="search"
                    @clear="handleClearSearch"
                  >
                  </el-input>
                </base-input>

              </div>

            <!-- Desktop Table -->
            <el-table v-if="!isMobile" :data="projects">
              <el-table-column
                class="mobile-stack"
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column  width="50">
                <template slot-scope="scope">
                  <el-radio v-model="selectedProject" :label="scope.row.id"></el-radio>
                </template>
               </el-table-column>
            </el-table>

             <!-- Mobile Table -->
            <el-table v-else :data="projects" >
              <el-table-column :label="$t('common.info')" min-width="150">
                <template slot-scope="scope">
                  <div>
                    <div><strong>{{$t('projects.key')}}:</strong> {{ scope.row.key }}</div>
                    <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column  width="50">
                <template slot-scope="scope">
                  <el-radio class="hidden-label" v-model="selectedProject" :label="scope.row.id"></el-radio>
                </template>
               </el-table-column>
            </el-table>

            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
              @input="updatePage"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
        </div>
      </card>

    </div>

    <div class="row">
      <div class="col-sm d-flex justify-content-end">
          <base-button @click="externalReserve" type="standard" fill>{{$t('common.submit')}}</base-button>
      </div>
    </div>
</card>
</template>
<script>
import api from "../../services/api"
import {Select, Option,Table, TableColumn,Radio } from 'element-ui';
import { BasePagination } from "@/components";



export default {
  props: {
      toolId: {
      type: String,
      default: '',
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-radio': Radio

  },
  computed: {
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
data() {
  return {
    selectedProject: null,
    initalPage: true,
    pagination: {
      perPage: 10,
      currentPage: 1,
      total: 0
    },
    isMobile: window.innerWidth <= 768,
    connectToProject: false,
    externalUser: '',
    loanOrRent: '',
    searchQuery: '',
    projects: [],
    tableColumns: [
      { prop: 'key', label: this.$t('projects.key'), minWidth: 150 },
      { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
    ],
  };
},
created() {
  this.fetchProjectData();
},
computed: {
  to() {
    let highBound = this.from + this.pagination.perPage;
    if (this.total < highBound) {
      highBound = this.total;
    }
    return highBound;
  },
  from() {
    return this.pagination.perPage * (this.pagination.currentPage - 1);
  },
  total() {
    return this.pagination.total;
  }
},
methods: {
  onConnectToProjectChange(){
    //clear selected project if we close the table
    if(this.connectToProject === false){
      this.selectedProject = null
    }
  },
  search(){
    this.pagination.currentPage = 1;
    this.pagination.total = 0;

    let getProjectsUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
    api.get(getProjectsUrl)
    .then(response => {
        this.pagination.total = response.data.totalItems;
        this.projects = response.data.result.map(item => {
        return {
            id: item.id,
            key: item.key,
            name: item.name,
        };
        });
    })
    .catch(error => {
      console.error('API request error:', error);
      this.$notify({
        message: this.$t('common.unableToRetrieveData'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
    });
  },
  handleClearSearch(){
    this.fetchProjectData();
  },
  updatePage(item){
    this.pagination.currentPage = item;
    if(this.initalPage){
      this.initalPage = false;
    }
    else{
        this.fetchProjectData()
    }
  },
  fetchProjectData(){
     let projectUrl = ''
      if(this.searchQuery === '')
      {
        projectUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
      }
      else
      {
        projectUrl = `/Project?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
      }
      api.get(projectUrl)
      .then(response => {
          this.pagination.total = response.data.totalItems;
          this.projects = response.data.result.map(item => {
          return {
              id: item.id,
              key: item.key,
              name: item.name,
          };
          });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
  },
  cancel()
  {
    this.$emit('close');
  },
  async externalReserve() {
    if (this.validateInput()) {
      let rented = true;
      if(this.loanOrRent === this.$t('tools.loan')){
        rented = false;
      }
      const response = await api.post('/Tool/Reserve/External', {
          toolId: this.toolId,
          externalUser: this.externalUser,
          rented: rented,
          projectId: this.selectedProject
      }).then(response =>
      {
        this.$notify({
          message: this.$t('tools.toolLoanedRented'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success",
          timeout: 1500,
        });
      }).catch(error =>
      {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('tools.unableToLoanRent'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
      });
      this.$emit('refreshData');
      this.$emit('close');
    }
  },
  validateInput() {
    let success = true;
    if (this.externalUser === '') {
      this.$refs.externalUserInput.focus();
      this.$notify({
        message: this.$t('errors.externalUserEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    else if (this.loanOrRent === '') {
      this.$refs.loanRentInput.focus();
      this.$notify({
        message: this.$t('errors.loanRentEmpty'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
        timeout: 2500,
      });
      success = false;
    }
    return success;
  }
}
};
</script>
<style>
.el-radio__label {
  display: none !important;
}
</style>
