<template>
    <div class="container">
      <div class="col-lg-4 col-md-6 ml-auto mr-auto">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-login card-black">
              <template slot="header">
                <div class="image-container">
                  <img src="img/tolsporA5.png" alt=""/>
                </div>
                <h1 class="card-title"></h1>
              </template>
  
              <div>
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors, validated }"
                >
                  <base-input
                    required
                    v-model="email"
                    type="email"
                    placeholder="Email"
                    addon-left-icon="tim-icons icon-email-85"
                    :error="validated && email && errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': validated && failed && email}]">
                  </base-input>
                </ValidationProvider>
              </div>
  
              <div slot="footer">
                <base-button native-type="submit" type="tolsporagulur" class="mb-3" size="lg" block>
                  Email reset password link
                </base-button>
              </div>
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </template>
  
  <script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import api from "../../services/api";
  
  extend("email", email);
  extend("required", required);
  
  export default {
    data() {
      return {
        email: "",
      };
    },
    methods: {
      async submit() {
        try {
          let resetUrl = `/Identity/ResetPasswordLink`;
          await api.post(resetUrl, { email: this.email });
          this.email = "";
          this.$notify({
            message: this.$t('users.passwordResetLinkSent'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
        } catch (error) {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('users.unableToSendResetLink'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
      }
    }
  };
  </script>
  
  <style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
  .image-container {
    height: 250px; /* Adjust as needed */
    overflow: hidden;
    position: relative;
  }
  </style>
  