<template>
<div class="row">
    <div class="col-md-4">
    <card>
        <h2>{{ toolGroup.name }}</h2>
        <img v-if="toolGroup.toolGroupImageBytes" class="toolImage" :src="'data:image/png;base64,' + toolGroup.toolGroupImageBytes" alt="tool alt text" />
        <h3 v-if="this.licencePlate">{{ this.licencePlate }}</h3>
        <h4 v-if="this.reservedBy">{{this.$t('cars.reservedBy')}} {{ this.reservedBy }}</h4>

    </card>
    <div v-if="this.userRole !== 'User'" class="row" style="padding-bottom: 20px;">
        <div v-if="!this.reservedBy && !showReserveTable" class="col-md-12" >
          <base-button block type="standard" @click="openReserveUserTable" >{{$t('cars.assignToUser')}}</base-button>

        </div>
        <div v-if="this.reservedBy" class="col-md-12">
          <base-button block type="standard" @click="unreserve" >{{$t('cars.unreserve')}}</base-button>

        </div>
    </div>
    <div class="row">
        <user-reserve-table :toolGroupId="this.toolGroupId"  v-if="showReserveTable" @close="closeReserveUserTable" @refreshData="fetchData"></user-reserve-table>
    </div>
    </div>
    <div class="col-md-8">
    <add-tool-table :toolGroupId="this.toolGroupId" v-if="showAddToolTable" @close="closeAddToolTable" @refreshData="fetchData"></add-tool-table>
     <card card-body-classes="table-full-width" :title="$t('cars.toolsInCar')" :onAddObject="this.userRole !== 'User' ? openAddToolTable : null">
      <div>

        <!-- Desktop Table -->
        <el-table v-if="!isMobile" :data="tools" :row-class-name="tableRowClassName">
          <el-table-column
                prop="toolImageThumbnailBytes"
                :label="$t('common.image')"
                width="80"
              >
                <template slot-scope="scope">
                  <img :src="'data:image/png;base64,' + scope.row.toolImageThumbnailBytes" alt="Tool Image" />
                  </template>
              </el-table-column>
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"

          >
          </el-table-column>
          <el-table-column v-if="this.userRole !== 'User'" :min-width="135" align="right" label="">
            <div slot-scope="props">
              <base-button
                @click.native.stop="removeTool(props.row.id)"
                class="remove btn-link"
                :class="{ 'black-button': props.row.toolReservations.length > 0 }"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>

        <!-- Mobile Table -->
        <el-table v-else :data="tools" :row-class-name="tableRowClassName">
          <el-table-column :label="$t('common.image')">
            <template slot-scope="scope">
                  <img :src="'data:image/png;base64,' + scope.row.toolImageThumbnailBytes" alt="Tool Image" />
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.info')" min-width="190">
            <template slot-scope="scope">
              <div>
                <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                <div><strong>{{$t('tools.brand')}}:</strong> {{ scope.row.brandName }}</div>
                <div v-if="userRole !== 'User'">
                  <base-button
                    @click.native.stop="removeTool(scope.row.id)"
                    class="remove btn-link"
                    :class="{ 'black-button': scope.row.toolReservations.length > 0 }"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>


      </div>
    </card>
    </div>
</div>
</template>

<script>
import api from "../../services/api";  // Import your API service
import AddToolTable from "./AddToolTable";
import UserReserveTable from "./UserReserveTable";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import swal from 'sweetalert2';


export default {
components: {
    AddToolTable,
    UserReserveTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
},
data() {
    return {
    toolGroup: {},
    tools:[],
    showAddToolTable: false,
    showReserveTable: false,
    toolGroupId: 0,
    reservedBy: '',
    licencePlate:'',
    showToolId: Cookies.get('showToolId'),
    tableColumns: [
      {
        prop: 'name',
        label: this.$t('common.name'),
        minWidth: 150
      },
      {
        prop: 'description',
        label: this.$t('common.description'),
        minWidth: 240
      },
      {
        prop: 'brandName',
        label: this.$t('tools.brand'),
        minWidth: 150
      }
    ],
      isMobile: window.innerWidth <= 768,
      userRole: '',
  };
},
created() {
  this.toolGroupId = this.$route.params.toolGroupId
  const token = Cookies.get('accessToken');
  const decodedToken = jwtDecode(token);
  this.userRole = decodedToken.role
  this.fetchData();
},
computed: {
},
methods: {
    tableRowClassName({ row }) {
      if (row.toolReservations && row.toolReservations.length > 0) {
        return 'red-row'; // This is the name of the CSS class you want to apply
      }
      return '';
    },
    fetchData()
    {
      this.tools = [];
      let toolGroupUrl = `/ToolGroup/${this.toolGroupId}`;
      api.get(toolGroupUrl)
          .then(response => {
            this.toolGroup = response.data;
            if(this.toolGroup.toolToToolGroups && this.toolGroup.toolToToolGroups.length > 0){
              this.toolGroup.toolToToolGroups.forEach((item,index) => {
                if (this.showToolId === "true") {
                  item.tool.name = `#${item.tool.customerToolId} ${item.tool.name}`;
                }
                this.tools.push(item.tool);
            });
            }

            if(this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true) && this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true).length > 0)
            {
                this.reservedBy = this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true)[0].user.firstName + ' ' + this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true)[0].user.lastName
            }
            else{
              this.reservedBy = ''
            }
            if(this.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate'))
            {
              this.licencePlate = this.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
            }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
    },
    async removeTool(id)
    {
        swal.fire({
        title: this.$t('cars.areYouSureRemove'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('cars.yesRemoveIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          api.put('/ToolGroup/RemoveTool', {
            toolGroupId: this.toolGroupId,
            toolId: id
          }).then(response => {
            this.fetchData();
            swal.fire({
              title: this.$t('cars.removedFromCar'),
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          })
          .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: this.$t('common.unableToUpdate'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
              });
            });
        }
      });
    },
    async unreserve()
    {
      swal.fire({
        title: this.$t('cars.areYouSureReturn'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('cars.yesReturnIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          api.put(`/ToolGroup/Unreserve/${this.toolGroupId}`).then(response => {
          this.fetchData();
          swal.fire({
            title: this.$t('cars.returned'),
            text: `${this.$t('cars.youReturned')} ${this.toolGroup.name}`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToUpdate'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 0,
            });
          });
        }
      });
    },
    openReserveUserTable()
    {
    this.showReserveTable = true;
    },
    closeReserveUserTable()
    {
    this.showReserveTable = false;
    },
    openAddToolTable()
    {
    this.showAddToolTable = true;
    },
    closeAddToolTable()
    {
    this.showAddToolTable = false;
    },

},
};
</script>
<style>
.red-row {
  background-color: rgb(214, 123, 123)!important;
}
.black-button {
  color: black !important;
}

</style>
