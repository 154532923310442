<!-- eslint-disable vue/no-mutating-props -->
<template>
<card card-body-classes="table-full-width" :title="$t('cars.addTools')" :onCancel="cancel">
  <div>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <base-input>
        <el-input
          class="mb-3 search-input"
          clearable
          prefix-icon="el-icon-search"
          :placeholder="$t('common.searchRecords')"
          v-model="searchQuery"
          aria-controls="datatables"
          @keyup.enter.native="search"
          @clear="handleClearSearch"
        >
        </el-input>
      </base-input>
    </div>

    <!-- Desktop Table -->
    <el-table v-if="!isMobile" :data="tools">
      <el-table-column :label="$t('common.image')">
        <template slot-scope="scope">
          <img :src="'data:image/png;base64,' + scope.row.image" alt="Tool Image" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in tableColumns"
        :key="column.label"
        :min-width="column.minWidth"
        :prop="column.prop"
        :label="column.label"

      >
      </el-table-column>
      <el-table-column :min-width="100" align="right" label="">
        <div slot-scope="props">
          <base-button
            @click.native="addTool(props.row.id)"
            class="green-text btn-link"
            type="info"
            size="sm"
            icon
          >
            <i class="tim-icons icon-bus-front-12 green-text"></i>
          </base-button>

        </div>
      </el-table-column>
    </el-table>

     <!-- Mobile Table -->
    <el-table v-else :data="tools">
      <el-table-column label="Image">
        <template slot-scope="scope">
          <img :src="'data:image/png;base64,' + scope.row.image" alt="Tool Image" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.info')" min-width="180">
        <template slot-scope="scope">
          <div>
            <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
            <div><strong>{{$t('tools.brand')}}:</strong> {{ scope.row.brand }}</div>
            <div>
              <base-button
                @click.native="addTool(scope.row.id)"
                class="like btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="tim-icons icon-bus-front-12 green-text"></i>
              </base-button>
            </div>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
  <div
    slot="footer"
    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
  >
    <div class="">
      <p class="card-category">
        {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
      </p>
    </div>
    <base-pagination
    @input="updatePage"
      class="pagination-no-border"
      v-model="pagination.currentPage"
      :per-page="pagination.perPage"
      :total="total"
    >
    </base-pagination>
  </div>
</card>
</template>
<script>
import api from "../../services/api"
import { BasePagination } from 'src/components';
import { Table, TableColumn, Select, Option } from 'element-ui';
import Cookies from 'js-cookie';

export default {
props: ['toolGroupId'],
name: 'add-tool-table',
data() {
  return {
    pagination: {
      perPage: 10,
      currentPage: 1,
      total: 0
    },
    tableColumns: [
      {
        prop: 'name',
        label: this.$t('common.name'),
        minWidth: 100
      },
      {
        prop: 'description',
        label: this.$t('common.description'),
        minWidth: 240
      },
      {
        prop: 'brandName',
        label: this.$t('tools.brand'),
        minWidth: 200
      }
    ],
    searchQuery: '',
    tools: [],
    initalPage: true,
    isMobile: window.innerWidth <= 768,
    showToolId: Cookies.get('showToolId'),
  };
},
created() {
    this.fetchToolData()
},
components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
},
computed: {
  to() {
    let highBound = this.from + this.pagination.perPage;
    if (this.total < highBound) {
      highBound = this.total;
    }
    return highBound;
  },
  from() {
    return this.pagination.perPage * (this.pagination.currentPage - 1);
  },
  total() {
    return this.pagination.total;
  }
},
methods: {
  search(){
    this.pagination.currentPage = 1;
    this.pagination.total = 0;

    let getToolsUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&notInToolGroup=true&toolStatusId=1&seachString=${this.searchQuery}`
      api.get(getToolsUrl)
      .then(response => {
        console.log(response)
          this.pagination.total = response.data.totalItems;
          this.tools = response.data.result.map(item => {
          return {
              id: item.id,
              name: this.showToolId === "true" ? `#${item.customerToolId} ${item.name}` : item.name,
              description: item.description,
              brandName: item.brandName,
              image: item.toolImageThumbnailBytes
          };
          });
      })
      .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
      });

  },
  handleClearSearch(){
    this.fetchToolData();
  },
  updatePage(item){
    this.pagination.currentPage = item;
    this.fetchToolData()
  },
  cancel()
  {
    this.$emit('close');
  },
  fetchToolData()
  {
      let getToolsUrl = ''
      if(this.searchQuery === '')
      {
        getToolsUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&notInToolGroup=true&toolStatusId=1`
      }
      else
      {
        getToolsUrl = `/Tool?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&notInToolGroup=true&toolStatusId=1&searchString=${this.searchQuery}`
      }

      api.get(getToolsUrl)
      .then(response => {
          this.pagination.total = response.data.totalItems;
          this.tools = response.data.result.map(item => {
          return {
              id: item.id,
              name: this.showToolId === "true" ? `#${item.customerToolId} ${item.name}` : item.name,
              description: item.description,
              brandName: item.brandName,
              image: item.toolImageThumbnailBytes

          };
          });
      })
      .catch(error => {
          console.error('API request error:', error);
          this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
          });
      });
  },
  async addTool(toolId) {
    const response = await api.put('/ToolGroup/AddTool', {
        toolGroupId: this.toolGroupId,
        toolId: toolId
    }).then(response => {
        this.$notify({
              message: this.$t('cars.toolAdded'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
        });
    })
    .catch(error => {
        console.error('API request error:', error);
        this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
        });
    });

    this.fetchToolData();
    this.$emit('refreshData');
    //this.$emit('close');
  }
}
};
</script>
<style>
.green-text {
    color: green;
    font-size: 20px;
}
</style>
