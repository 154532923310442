<!-- eslint-disable vue/no-mutating-props -->
<template>
<card card-body-classes="table-full-width" :title="$t('users.users')" :onCancel="cancel">
  <div>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <base-input>
        <el-input
          class="mb-3 search-input"
          clearable
          prefix-icon="el-icon-search"
          :placeholder="$t('common.searchRecords')"
          v-model="searchQuery"
          aria-controls="datatables"
          @keyup.enter.native="search"
          @clear="handleClearSearch"
        >
        </el-input>
      </base-input>

    </div>
    <el-table :data="users">
      <el-table-column
        v-for="column in tableColumns"
        :key="column.label"
        :min-width="column.minWidth"
        :prop="column.prop"
        :label="column.label"

      >
      </el-table-column>
      <el-table-column :min-width="135" align="right" label="">
        <div slot-scope="props">
          <base-button
            @click.native="reserve(props.row.id)"
            class="like btn-link"
            type="info"
            size="sm"
            icon
          >
            <i class="tim-icons icon-shape-star"></i>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
  </div>
  <div
    slot="footer"
    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
  >
    <div class="">
      <p class="card-category">
        {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
      </p>
    </div>
    <base-pagination
    @input="updatePage"
      class="pagination-no-border"
      v-model="pagination.currentPage"
      :per-page="pagination.perPage"
      :total="total"
    >
    </base-pagination>
  </div>
</card>
</template>
<script>
import api from "../../services/api"
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Cookies from 'js-cookie';

export default {
props: ['toolGroupId'],
name: 'user-reserve-table',
components: {
  BasePagination,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [TableColumn.name]: TableColumn
  },
computed: {
  to() {
    let highBound = this.from + this.pagination.perPage;
    if (this.total < highBound) {
      highBound = this.total;
    }
    return highBound;
  },
  from() {
    return this.pagination.perPage * (this.pagination.currentPage - 1);
  },
  total() {
    return this.pagination.total;
  }
  },
data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: this.$t('common.name'),
          minWidth: 150
        }
      ],
      searchQuery: '',
      users: [],
      initalPage: true,
      showToolId: Cookies.get('showToolId'),
    };
},
created() {
    this.fetchUserData()
},
methods: {
  search(){
    this.pagination.currentPage = 1;
    this.pagination.total = 0;

    let getUsersUrl = `/User?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&name=${this.searchQuery}`
    api.get(getUsersUrl)
      .then(response => {
        this.pagination.total = response.data.totalItems;
        this.users = response.data.result.map(item => {
          return {
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
          };
        });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
  },
  handleClearSearch(){
    this.fetchUserData();

  },
  updatePage(item){
    this.pagination.currentPage = item;
    this.fetchUserData()
  },
  cancel()
  {
    this.$emit('close');
  },
  fetchUserData()
  {
      let getUsersUrl = ''
      if(this.searchQuery === '')
      {
          getUsersUrl = `/User?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
      }
      else
      {
        getUsersUrl = `/User?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&name=${this.searchQuery}`
      }
      api.get(getUsersUrl)
      .then(response => {
          this.pagination.total = response.data.totalItems;
          this.users = response.data.result.map(item => {
          return {
              id: item.id,
              name: item.firstName + ' ' + item.lastName,
          };
          });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
},
async reserve(userId) {
  const response = await api.post('/ToolGroup/Reserve', {
      toolGroupId: this.toolGroupId,
      userId: userId
  }).then(response => {
      this.$notify({
          message: this.$t('cars.carReserved'),
          icon: "tim-icons icon-check-2",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success",
          timeout: 1500,
      });
  })
  .catch(error => {
      console.error('API request error:', error);
      this.$notify({
          message: this.$t('common.unableToUpdate'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
      });
  });

  //this.fetchToolData();
  this.$emit('refreshData');
  this.$emit('close');
  }
}
};
</script>
<style></style>
