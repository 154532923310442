<!-- eslint-disable vue/no-mutating-props -->
<template>
<card class="stacked-form" :title="$t('cars.createCar')" :onCancel="cancel">
    <div class="row">
      <div class="col-md-6">
        <label class="col-md-3 col-form-label">{{$t('common.name')}} *</label>
        <div class="col-md-9">
          <base-input :placeholder="$t('common.name')" ref="nameInput" v-model=name> </base-input>
        </div>
        <label class="col-md-3 col-form-label">{{$t('cars.licencePlate')}} *</label>
        <div class="col-md-9">
          <base-input :placeholder="$t('cars.licencePlate')" ref="licencePlateInput" v-model=licencePlate> </base-input>
        </div>
      </div>
        <div class="col-md-6">
          <div class="col-md-9">
            <image-upload :showUploadButton="false" @change="handleFileUpload" :select-text="$t('image.selectImage')" />
          </div>
        </div>


    </div>
    <div class="row">
    <div class="col-sm d-flex justify-content-end">
        <base-button @click="saveCar" type="standard" fill>{{$t('common.submit')}}</base-button>
    </div>

    </div>
</card>
</template>
<script>
import api from "../../services/api"
import { ImageUpload } from 'src/components/index';


export default {
components: {
    ImageUpload
},
data() {
    return {
    name: '',
    licencePlate: '',
    toolGroupImageBytes: '',

    };
},
mounted() {
},
methods: {
  cancel()
  {
    this.$emit('close');
  },
  async saveCar() {
    if (this.validateInput()) {
      let requestBody = {};
      if(this.toolGroupImageBytes === '')
      {
        requestBody = {
          toolgrouptypeid: 1,
          name: this.name,
          properties: [{key:'licencePlate', value: this.licencePlate}]
        };
      }
      else{
        requestBody = {
          toolgrouptypeid: 1,
          name: this.name,
          toolGroupImageBytes: this.toolGroupImageBytes,
          properties: [{key:'licencePlate', value: this.licencePlate}]
        };
      }

      const response = await api.post('/ToolGroup', requestBody)
      .then(response =>
      {
        this.$notify({
          message: `${this.name} ${this.$t('common.created')}`,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success",
          timeout: 1500,
        });
        this.$router.push({ name: 'Car', params: { toolGroupId: response.data.id.toString() } });
      }).catch(error =>
      {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToCreate'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
    }
  },
  handleFileUpload(file) {
    if(file){
      const reader = new FileReader();

      reader.onloadend = () => {
        let binary = '';
        const bytes = new Uint8Array(reader.result);
        bytes.forEach((byte) => binary += String.fromCharCode(byte));
        this.toolGroupImageBytes = btoa(binary);
      };
      reader.readAsArrayBuffer(file);
    }
    else{
      this.toolGroupImageBytes = ''
    }
  },
  validateInput() {
    let success = true;
      if (this.name === '') {
        this.$refs.nameInput.focus();
        this.$notify({
          message: this.$t('errors.nameEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.licencePlate === '') {
        this.$refs.licencePlateInput.focus();
        this.$notify({
          message: this.$t('errors.licencePlateEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolGroupImageBytes === '') {
        this.$notify({
          message: this.$t('errors.imageEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
  }
}
};
</script>
<style></style>
