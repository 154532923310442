<template>
  <div class="app-settings">
    <h1>{{$t('appSettings.appSettings')}}</h1>

    <div class="row">
      <!-- Color Settings -->
      <div class="col-md-6">
        <card class="stacked-form" :title="$t('appSettings.sidebarBackgroundColor')">
          <div class="settings-section">
            <div class="badge-colors text-left d-flex flex-row">
              <div
                v-for="item in sidebarColors"
                :key="item.color"
                class="color-option"
              >
                <input
                  type="radio"
                  :id="item.color"
                  :value="item.color"
                  v-model="selectedColor"
                  @change="changeSidebarBackground(item)"
                  class="color-radio"
                />
                <label :for="item.color" :class="`color-label badge-${item.color}`">
                  <span :class="[`badge-${item.color}`, { active: item.active }]"></span>
                </label>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- Light/Dark Mode Settings -->
      <div class="col-md-6">
        <card class="stacked-form" :title="$t('appSettings.darkLightMode')">
          <div class="settings-section">
            <div class="togglebutton switch-change-color">
              <i class="fas fa-sun fa-3x"></i>
              <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
              <i class="fas fa-moon fa-3x"></i>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseSwitch } from 'src/components';

export default {
  name: 'app-settings',
  components: {
    BaseSwitch
  },
  props: {
    backgroundColor: String
  },
  data() {
    return {
      darkMode: false,
      selectedColor: 'tolsporagulur',
      sidebarColors: [
        { color: 'primary', active: false, value: 'primary' },
        { color: 'vue', active: false, value: 'vue' },
        { color: 'tolsporagulur', active: true, value: 'tolsporagulur' },
        { color: 'success', active: false, value: 'green' },
        { color: 'warning', active: false, value: 'orange' },
        { color: 'danger', active: false, value: 'red' }
      ]
    };
  },
  mounted() {
    // Check if 'darkMode' is stored in localStorage
    this.darkMode = localStorage.getItem('darkMode') === 'true' || false;
    let storedColor = localStorage.getItem('selectedColor');

    // If 'selectedColor' is found in localStorage, update 'selectedColor' data property
    if (storedColor) {
      if (storedColor === 'green') {
        this.selectedColor = 'success';
      } else if (storedColor === 'orange') {
        this.selectedColor = 'warning';
      } else if (storedColor === 'red') {
        this.selectedColor = 'danger';
      } else {
        this.selectedColor = storedColor;
      }
    }
  },
  methods: {
    changeSidebarBackground(item) {
      // Set the data attribute of the sidebar and main-panel elements
      let sidebarElement = document.querySelector('.sidebar');
      let mainPanelElement = document.querySelector('.main-panel');

      if (sidebarElement && mainPanelElement) {
        sidebarElement.setAttribute('data', item.value);
        mainPanelElement.setAttribute('data', item.value);
      }

      // Update the selected color
      this.selectedColor = item.color;

      // Modify the value to be stored based on item.color
      let colorToStore = item.color;
      if (item.color === 'success') {
        colorToStore = 'green';
      } else if (item.color === 'warning') {
        colorToStore = 'orange';
      } else if (item.color === 'danger') {
        colorToStore = 'red';
      }

      localStorage.setItem('selectedColor', colorToStore); // Store the selected color in localStorage
      this.toggleList(this.sidebarColors, item);
    },
    toggleMode(type) {
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove('white-content');
      } else {
        docClasses.add('white-content');
      }
      localStorage.setItem('darkMode', type); // Store the dark mode setting in localStorage
    },
    toggleList(list, itemToActivate) {
      list.forEach(listItem => {
        listItem.active = false;
      });
      itemToActivate.active = true;
    }
  }
};
</script>

<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

.badge-vue {
  background-color: $vue;
}

.color-option {
  display: flex;
  align-items: center;
}

.color-radio {
  display: none;
}

.color-label {
  display: inline-block;
  width: 50px;  /* Increase the size of the circle */
  height: 50px;  /* Increase the size of the circle */
  border-radius: 50%;
  position: relative;
}

.color-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;  /* Adjust the size of the inner circle */
  height: 20px;  /* Adjust the size of the inner circle */
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.color-radio:checked + .color-label::before {
  opacity: 1;
}
</style>
