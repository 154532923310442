<template>
  <card type="user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <h2>{{$t('users.workersPermit')}}</h2>
      <h4>{{ user.companyName }} kt: {{ user.companyIdentificationNumber }}</h4>
      <template>
        <a href="#">
          <img class="avatar" :src="'data:image/png;base64,' + user.base64ImageData" alt="..." />
          <h5 class="title">{{ user.fullName }}</h5>
          <h6 class="title">{{ user.identificationNumber }}</h6>
        </a>
      </template>
      <p class="description">
        <a :href="'mailto:' + user.email">{{ user.email }}</a>

      </p>
      <p class="description">
       <a :href="'tel:' + user.phoneNumber">{{ user.phoneNumber }}</a>
      </p>
      <p class="description">
        <a>{{ user.employeeTitle }}</a>
      </p>
    </div>
  </card>
</template>
<script>

export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fileUpload: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
<style></style>
