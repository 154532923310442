<!-- StatusMap.vue -->

<template>
  <div>
    <!-- No need for a template in this component -->
  </div>
</template>

<script>
  const statusMap = {
    1: "Free",
    2: "In Use",
    3: "Broken",
    4: "Out of Use",
    5: "Loaned",
    6: "Rented",
  };

  export default statusMap;
  </script>
