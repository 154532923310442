<template>
<div class="row">
    <div class="col-12">
        <div class="col-md-12">
        <create-car-form v-if="showForm" @close="closeForm" @refreshData="fetchData"></create-car-form>
        </div>
    <card card-body-classes="table-full-width" :title="$t('cars.cars')" :onAddObject="openForm">
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <base-input>
            <el-input
              class="mb-3 search-input"
              clearable
              prefix-icon="el-icon-search"
              :placeholder="$t('common.searchRecords')"
              v-model="searchQuery"
              aria-controls="datatables"
              @keyup.enter.native="search"
              @clear="handleClearSearch"
            >
            </el-input>
          </base-input>
        </div>
        <!-- Desktop Table -->
        <el-table v-if="!isMobile" :data="cars" @row-click="goToCar">
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"

          >
          </el-table-column>
          <el-table-column :min-width="135" align="right" label="">
            <div slot-scope="props">
              <base-button
                @click.native.stop="handleDelete(props.$index, props.row)"
                class="remove btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>

        <!-- Mobile Table -->
        <el-table v-else :data="cars" @row-click="goToCar">
              <el-table-column :label="$t('common.info')" min-width="150">
                <template slot-scope="scope">
                  <div>
                    <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                    <div><strong>{{$t('cars.licencePlate')}}:</strong> {{ scope.row.licencePlate }}</div>
                    <div><strong>{{$t('cars.numberOfTools')}}:</strong> {{ scope.row.numberOfTools }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="">
                <div slot-scope="props">
                  <base-button
                    @click.native.stop="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>


      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
          </p>
        </div>
        <base-pagination
        @input="updatePage"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>

    </div>
</div>
</template>
<script>
import api from "../../services/api"
import CreateCarForm from "./CreateCarForm";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import swal from 'sweetalert2';


export default {
components: {
    CreateCarForm,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
},
computed: {
  to() {
    let highBound = this.from + this.pagination.perPage;
    if (this.total < highBound) {
      highBound = this.total;
    }
    return highBound;
  },
  from() {
    return this.pagination.perPage * (this.pagination.currentPage - 1);
  },
  total() {
    return this.pagination.total;
  }
  },
data() {
    return {
      showForm: false,
      cars: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: this.$t('common.name'),
          minWidth: 200
        },
        {
          prop: 'licencePlate',
          label: this.$t('cars.licencePlate'),
          minWidth: 200
        },
        {
          prop: 'numberOfTools',
          label: this.$t('cars.numberOfTools'),
          minWidth: 100
        },
        {
          prop: 'reservedBy',
          label: this.$t('cars.reservedBy'),
          minWidth: 200
        }
      ],
      searchQuery: '',
      isMobile: window.innerWidth <= 768,
      initalPage: true

    };
},
methods: {
updatePage(item){
  this.pagination.currentPage = item;
  this.fetchData()
},
search(){
    this.pagination.currentPage = 1;
    this.pagination.total = 0;

    let getToolGroupUrl = `/ToolGroup?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
    api.get(getToolGroupUrl)
    .then(response => {
        this.pagination.total = response.data.totalItems;
        this.cars = response.data.result.map(item => {
        let numberOfTools = 0
          if(item.toolToToolGroups && item.toolToToolGroups.length > 0)
          {
            numberOfTools = item.toolToToolGroups.length
          }
          let reservedBy = ''
          if(item.toolGroupReservations && item.toolGroupReservations.length > 0)
          {
            reservedBy = item.toolGroupReservations[0].user.firstName + ' ' + item.toolGroupReservations[0].user.lastName
          }
          let licencePlate = ''
          if(item.toolGroupProperties.filter(p => p.key === 'licencePlate'))
          {
            licencePlate = item.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
          }
          return {
              id: item.id,
              name: item.name,
              reservedBy: reservedBy,
              licencePlate: licencePlate,
              numberOfTools: numberOfTools
          };
        });
    })
    .catch(error => {
      console.error('API request error:', error);
      this.$notify({
        message: this.$t('common.somethingWentWrong'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
    });

},
handleClearSearch()
{
    this.fetchData();
},
handleDelete(index, row){
  swal.fire({
    title: this.$t('delete.areYouSure'),
    text: this.$t('delete.noRevert'),
    icon: 'warning',
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill'
    },
    confirmButtonText: this.$t('delete.yesDeleteIt'),
    cancelButtonText: this.$t('delete.cancel'),
    buttonsStyling: false
  }).then(result => {
    if (result.value) {
      this.deleteToolGroup(row);
    }
  });
},
deleteToolGroup(row)
{
  let deleteUrl = `/ToolGroup/` + row.id
  api.delete(deleteUrl)
  .then(response => {
    this.cars = this.cars.filter(car => car.id !== row.id);
    swal.fire({
      title: this.$t('delete.deleted'),
      text: `${this.$t('delete.youDeleted')} ${row.name}`,
      icon: 'success',
      confirmButtonClass: 'btn btn-success btn-fill',
      buttonsStyling: false
    });
  })
  .catch(error => {
    console.error('API request error:', error);
    this.$notify({
      message: this.$t('common.unableToDelete'),
      icon: "tim-icons icon-alert-circle-exc",
      horizontalAlign: "center",
      verticalAlign: "top",
      type: "danger",
      timeout: 0,
    });
  });
},
openForm()
{
  this.showForm = true;
  this.$nextTick(() => {
    window.scrollTo(0, 0);
  });
},
closeForm()
{
  this.showForm = false;
},
goToCar(item) {
  this.$router.push({ name: 'Car', params: { toolGroupId: item.id.toString() } });
},
fetchData() {
    let getToolGroupUrl  = ''
    if(this.searchQuery === '')
    {
        getToolGroupUrl = `/ToolGroup?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
    }
    else
    {
      getToolGroupUrl = `/ToolGroup?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&searchString=${this.searchQuery}`
    }
    api.get(getToolGroupUrl)
    .then(response => {
        this.pagination.total = response.data.totalItems;
        this.cars = response.data.result.map(item => {
          let numberOfTools = 0
          numberOfTools = item.toolToToolGroupsCount
          let reservedBy = ''
          if(item.toolGroupReservations.filter(p => p.isReserved === true) && item.toolGroupReservations.filter(p => p.isReserved === true).length > 0)
          {
            reservedBy = item.toolGroupReservations.filter(p => p.isReserved === true)[0].user.firstName + ' ' + item.toolGroupReservations.filter(p => p.isReserved === true)[0].user.lastName
          }
          let licencePlate = ''
          if(item.toolGroupProperties.filter(p => p.key === 'licencePlate'))
          {
            licencePlate = item.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
          }
          return {
              id: item.id,
              name: item.name,
              reservedBy: reservedBy,
              licencePlate: licencePlate,
              numberOfTools: numberOfTools
          };
        });
    })
    .catch(error => {
      console.error('API request error:', error);
      this.$notify({
        message: this.$t('common.unableToRetrieveData'),
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
      });
    });
    },
},
created() {
  this.fetchData();
},
};
</script>
<style></style>
