<template>
  <div class="row" v-if="dataReady">
    <div class="col-md-4"><user-card :user="user"> </user-card></div>
  </div>
</template>
<script>
import UserCard from '../Pages/UserProfile/UserCard.vue';
import { defaultUserImage } from '../../components/Image Bytes/imagebytes'
import api from "../../services/api"

export default {
  components: {
    UserCard,
  },
  data() {
    return {
      user: {
        id: "",
        fullName: "",
        title: "",
        base64ImageData: "",
        identificationNumber: "",
        employeeTitle: "",
        companyIdentificationNumber: "",
        companyName: "",
        companyLogo: ""
      },
      dataReady: false
    };
  },
  created() {
    this.user.id = this.$route.params.userId
    let userUrl = `/User/${this.user.id}`
    api.get(userUrl)
      .then(response => {
        this.user.fullName = response.data.firstName + " " + response.data.lastName;
        this.user.email = response.data.email;
        this.user.phoneNumber = response.data.phoneNumber;
        this.user.base64ImageData = response.data.userImageThumbnailBytes;
        this.user.base64ImageData = response.data.userImageThumbnailBytes !== null ? response.data.userImageThumbnailBytes: defaultUserImage
        this.user.identificationNumber = response.data.identificationNumber;
        this.user.employeeTitle = response.data.employeeTitle;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: `Unable to fetch user information: ${error.message}`,
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });

    let customerUrl = `/Customers/Me`
    api.get(customerUrl)
      .then(response => {
        this.user.companyName = response.data.value.name;
        this.user.companyIdentificationNumber = response.data.value.identificationNumber;
        this.user.companyLogo = response.data.value.logoBytes;
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
      });

    this.dataReady = true;
  },
};
</script>
<style></style>
