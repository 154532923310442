<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card :title="$t('locations.createLocation')" :onCancel="cancel">
    <div class="row">
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.address')}} *</label>
        <base-input
          ref="addressInput"
          v-model="address"
          :placeholder="$t('locations.address')"
        >
        </base-input>
      </div>

      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('common.type')}} *</label>
        <div class="row" style="padding-left: 18px;">

         <el-select
              ref="locationTypeInput"
              v-if="selectedOption !== 'custom'"
              :class="selectClass"
              size="medium"
              :placeholder="$t('common.selectType')"
              v-model="selectedOption"
            >
              <el-option
                v-for="item in options"
                :class="selectClass"
                :value="item.type"
                :label="item.type"
                :key="item.id"
              >
              </el-option>
                <el-option
                  :class="selectClass"
                  value="custom"
                  :label="$t('common.addNewType')"
                >
                </el-option>
          </el-select>

          <base-input
            ref="newLocationTypeInput" 
            v-model="newLocationType"
            v-if="selectedOption === 'custom'"
            :placeholder="$t('common.type')"
            required
          >
          </base-input>
          <span v-if="selectedOption === 'custom'" class="cancel-new-type" @click="cancelNewLocationType">
            <i class="tim-icons icon-simple-remove"></i>
          </span>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.latitude')}}</label>
        <base-input
          v-model="latitude"
          :placeholder="$t('locations.latitude')"
        >
        </base-input>
      </div>
      <div class="col-md-4">
        <label class="col-md-4 col-form-label">{{$t('locations.longitude')}}</label>

        <base-input
          v-model="longitude"
          :placeholder="$t('locations.longitude')"
        >
        </base-input>
      </div>
    </div>
    <div class="row">

      <div class="col-sm d-flex justify-content-end">
        <base-button @click="saveLocation" type="standard" fill>{{$t('common.submit')}}</base-button>
      </div>
    </div>
  </card>
</template>
<script>
import api from "../../services/api"
import {Select, Option } from 'element-ui';


export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    }
  },
  data() {
    return {
      options: [],
      selectedOption: '',
      longitude: '',
      latitude: '',
      address: '',
      newLocationType: ''

    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    cancelNewLocationType(){
      if(this.options.length>0){
        this.selectedOption = this.options[0].type
      }
      else{
        this.selectedOption = ''
      }
    },
    cancel()
    {
      this.$emit('close');
    },
    async saveLocation() {
      if (this.validateInput()) {
        let locationtypeid = 0
        if(this.newLocationType !== '')
        {
          const newLocationTypeResponse = await api.post('/LocationType', {
            type: this.newLocationType,
          });
          locationtypeid = newLocationTypeResponse.data.id
        }
        else
        {
          locationtypeid = this.options.filter(op => op.type === this.selectedOption)[0].id
        }
        if(this.latitude === ''){
          this.latitude = null
        }
        if(this.longitude === ''){
          this.longitude = null
        }

        const response = await api.post('/Location', {
          address: this.address,
          latitude: this.latitude,
          longitude: this.longitude,
          locationtypeid: locationtypeid
          }).then(request => {
              this.$notify({
                message: "Location created",
                icon: "tim-icons icon-check-2",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });

              this.$emit('refreshData');
              this.$emit('close');
          }).catch(error => {
            console.error(error)
            this.$notify({
              message: `Unable to create location: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
        });
      }
    },
    fetchData()
    {
      let getLocationTypessUrl = `/LocationType`
      api.get(getLocationTypessUrl)
      .then(response => {
          this.options = response.data
      })
      .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `Unable to retrieve location type data: ${error.message} <br> Please refresh the page`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
      });
    },
    validateInput() {
      let success = true;
      if (this.address === '') {
        this.$refs.addressInput.focus();
        this.$notify({
          message: this.$t('errors.addressEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.selectedOption === 'custom' && this.newLocationType === '') {
        this.$refs.newLocationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.newLocationTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.selectedOption === '') {
        this.$refs.locationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.locationEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
    }
  }
};
</script>
<style></style>
